import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { IEnvironmentService, IENVIRONMENT_SERVICE } from '../../../../../services/environment/environment.interface';
import { ITableDataResponse } from '../../../../facade/service-facade/data.response';
import { IDATA_SERVICE, IDataService } from 'src/app/core/services/data/data-service.interface';

@Injectable()
export class TableSettingsService {
  private endpointApi: string;
  private cache = new Map<string, Observable<any>>();

  constructor(
    private http: HttpClient,
    @Inject(IDATA_SERVICE) private dataService: IDataService,
    @Inject(IENVIRONMENT_SERVICE) private envService: IEnvironmentService
  ) {
    this.endpointApi = this.envService.getVar('services.api') as string;
  }

  public getTableSettings(table: string): Observable<{
    filters: ITableDataResponse['filters'];
    columns: ITableDataResponse['columns'];
  }> {
    const request =
      this.cache.get(table) ||
      this.http
        .post<any>(this.endpointApi + '/table/settings', {
          table,
        })
        .pipe(shareReplay());
    this.cache.set(table, request);
    return request;
  }

  public getTableColumnsSelect(table: string): Observable<Record<string, string>> {
    return this.getTableSettings(table).pipe(
      map((item) =>
        item.columns.reduce<Record<string, string>>(
          (acc, item) => ((acc[item.id] = this.dataService.getData().tablesLabelsFilters?.[item.label] || item.label), acc),
          {}
        )
      )
    );
  }

  public getOrderedTableColumnsSelect(table: string): Observable<Record<string, string>> {
    return this.getTableSettings(table).pipe(
      map((item) =>
        item.columns
          .filter((column) => !column.disableOrder)
          .reduce<Record<string, string>>(
            (acc, item) => ((acc[item.id] = this.dataService.getData().tablesLabelsFilters?.[item.label] || item.label), acc),
            {}
          )
      )
    );
  }

  public getTableFiltersSelect(table: string): Observable<Record<string, string>> {
    return this.getTableSettings(table).pipe(
      map((item) => item.filters.reduce<Record<string, string>>((acc, item) => ((acc[item.id] = item.label), acc), {}))
    );
  }
}
